import React from "react";
import "./Team.css";
import Card from "./Card";
import rekhakaushik from "./images/REKHA KAUSHIK.jpg";
import afreenkhursheed from "./images/Afreen Khursheed.jpg";
import anjaniKumar from "./images/Anjani Kumar.jpg";
import sanskarprasad from "./images/Sanskar_prasad.jpg";
import kritikjain from "./images/Kritik Jain.jpg";
import rythmduggal from "./images/Rhythm Duggal.jpg";
import dhyand from "./images/Dhyan D.jpg";
import kshitijnigam from "./images/Kshitij Nigam.jpg";
import aditimishra from "./images/Aditi Mishra.jpg";
import shubhammali from "./images/Shubham Mali.jpg";
import ashmitrajput from "./images/Ashmit Rajput.jpg";
import akshatchitransh from "./images/Akshat chitransh.jpg";
import abhisheksingh from "./images/Abhishek-Singh.jpg";


function Team() {
	return (
		<>
			<div id="team">
				<center>
					<div class="justify-content-center">
						<h1 className="heading">Our Team</h1>
						<hr style={{width: "20%", height: "1px", color: "black"}} />
					</div>
					<br />
					<br />
					<h2>Faculty Members</h2>
					<div class="container reveal fade-bottom">
						<div class="row">
							<div className="col-lg-4 col-sm-12">
								<Card name="Dr. Afreen Khursheed" role="Branch Counsellor" src={afreenkhursheed} />
							</div>
							<div className="col-lg-4 col-sm-12">
								<Card name="Dr. Rekha Kaushik" role="Faculty Coordinator" src={rekhakaushik} />
							</div>
							<div className="col-lg-4 col-sm-12">
								<Card name="Dr. Anjani Kumar" role="Faculty Coordinator" src={anjaniKumar} />
							</div>
						</div>
					</div>
					<br />
					<br />
					<br />
					<br />
					<br />
					<h2>Student Executives</h2>
					<div class="container reveal fade-bottom">
						<div class="row">
							<div className="col-lg-3 col-sm-6">
								<Card name="Mr. Sanskarkumar Prasad" role="Chairperson" src={sanskarprasad} />
							</div>
							<div className="col-lg-3 col-sm-12">
								<Card name="Mr. Abhishek Singh" role="Vice Chairperson" src={abhisheksingh} />
							</div>
							<div className="col-lg-3 col-sm-12">
								<Card name="Mr. Kritik Jain" role="Vice Chairperson" src={kritikjain} />
							</div>
							<div className="col-lg-3 col-sm-6">
								<Card name="Mr. Rhythm Duggal" role="Secretary" src={rythmduggal} />
							</div>
						</div>
					</div>
					<div class="container reveal fade-bottom">
						<div class="row">
							<div className="col-lg-3 col-sm-6">
								<Card name="Mr. Kshitij Nigam" role="Treasurer" src={kshitijnigam} />
							</div>
							<div className="col-lg-3 col-sm-6">
								<Card name="Ms. Aditi Mishra" role="Web Master-I" src={aditimishra} />
							</div>
							<div className="col-lg-3 col-sm-6">
								<Card name="Mr. Shubham Mali" role="Web Master-II" src={shubhammali} />
							</div>
							<div className="col-lg-3 col-sm-6">
								<Card name="Mr. Ashmit Rajput" role="Design Coordinator" src={ashmitrajput} />
							</div>
						</div>
						{/* <br /> */}
					</div>
					<div class="container reveal fade-bottom">
						<div class="row">
							<div className="col-lg-3 col-sm-6 disableAtCondition"></div>
							<div className="col-lg-3 col-sm-12">
								<Card name="Mr. Dhyan D" role="Publicity & Social Media Coordinator" src={dhyand} />
							</div>
							<div className="col-lg-3 col-sm-12">
								<Card name="Mr. Akshat Chitransh" role="Membership Head" src={akshatchitransh} />
							</div>

							<div className="col-lg-3 col-sm-6 disableAtCondition"></div>
						</div>
					</div>
				</center>
			</div>
		</>
	);
}

export default Team;
